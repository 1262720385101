import { NavButton } from 'src/components/Quiz/NavButton'
import { useQuiz } from 'src/providers/Quiz'

export const QuizFooter = () => {
  const { currentAnswer, isAnswerValid, onNavigateForward, nextRoute, isBusy, trackingAnswerData } =
    useQuiz()

  const isDisabled = React.useMemo(
    () =>
      (currentAnswer.value.length === 0 && currentAnswer.choices === 0) || !isAnswerValid || isBusy,
    [currentAnswer, isAnswerValid, isBusy]
  )

  return (
    <Styles.Container>
      <NavButton
        action={obe.events.quiz.answered_question}
        direction='right'
        buttonText='Next'
        onClickHandler={onNavigateForward}
        tracking={trackingAnswerData}
        to={nextRoute}
        disabled={isDisabled}
      />
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    width: 100%;
    height: 82px;
    background: var(--neutrals100);
    border-top: 1px solid var(--neutrals400);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--neutrals100);
  `,
}
