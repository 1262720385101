import * as Sentry from '@sentry/nextjs'
import { e164Formatted } from './utils'
import { useUser, loadUser } from 'src/hooks/useUser'
import { useAsyncCallback } from 'react-async-hook'
import { startSMSVerification } from 'src/models/profile'
import { useRouter } from 'src/hooks/useRouter'
import { QUESTION_TYPES, resultPageRoutes } from 'src/providers/Quiz/utils'
import { useQuizNavigation } from './useQuizNavigation'
import { useQuizData } from './useQuizData'
import { useQuizTracking } from './useQuizTracking'

export const QuizResultsContext = React.createContext()

export const useQuiz = () => React.useContext(QuizResultsContext)

export const QuizProvider = ({ quiz, questionSlug, setIsReversed, children }) => {
  const { push } = useRouter()
  const { currentUser } = useUser()
  const { questions } = quiz

  const {
    currentQuestion,
    currentQuestionIdx,
    previousAvailableQuestion,
    nextAvailableQuestion,
    nextQuestion,
    markQuestionToSkip,
    unmarkQuestionToSkip,
    isLastQuestion,
    isSkippingNextQuestion,
  } = useQuizNavigation(questions, questionSlug)

  const {
    previousAnswer,
    currentAnswer,
    isAnswerValid,
    setIsAnswerValid,
    isLoadingPreviousAnswer,
    submitAnswer,
    submitEmptyAnswer,
    clearAnswer,
    isSubmittingAnswer,
    optionSelectHandler,
    textInputHandler,
  } = useQuizData(currentQuestion, nextQuestion, quiz, markQuestionToSkip, unmarkQuestionToSkip)

  const { trackingData, trackingAnswerData } = useQuizTracking(quiz, currentQuestion, currentAnswer)

  // If invalid question route redirect to valid one
  React.useEffect(() => {
    if (questions?.length > 0 && currentQuestionIdx < 0) {
      push(`/quiz/${quiz.slug}/${questions[0].slug}`)
    }
  }, [currentQuestion, currentQuestionIdx, push, questions, quiz.slug])

  const { execute: startPhoneVerification } = useAsyncCallback(async (phoneNumber) => {
    try {
      await startSMSVerification({
        id: currentUser.id,
        phone: e164Formatted(phoneNumber),
      })
    } catch (err) {
      Sentry.captureException(err, { level: 'debug' })
    }
  })

  const { execute: onFinishQuiz, loading: isFinishingQuiz } = useAsyncCallback(async () => {
    // Send quiz finish tracking data
    obe.analytics.track(obe.events.quiz.completed, trackingData)

    // Reload user to set the correct conditional display attributes
    await loadUser()
  })

  const onNavigateForward = async () => {
    setIsReversed(false)

    // Send SMS verification code when moving forward on the phone number question
    // Do not send the phone number as an answer to the backend yet
    if (currentQuestion.questionType === QUESTION_TYPES.PHONE_NUMBER) {
      startPhoneVerification(currentAnswer.value)
      return
    }

    // If the current question is the phone verification and the user is moving forward
    // Send both the code and the phone number as answers to the backend
    if (currentQuestion.questionType === QUESTION_TYPES.PHONE_VERIFICATION) {
      const phoneNumberQuestion = questions.find(
        (question) => question.questionType === QUESTION_TYPES.PHONE_NUMBER
      )
      if (!phoneNumberQuestion) {
        // This should never occur in a quiz with the correct structure
        return
      }
      await submitAnswer(phoneNumberQuestion)
    }

    await submitAnswer(currentQuestion)

    // If the next question has been marked as a question to be skipped, submit an empty answer
    // for it as well
    if (isSkippingNextQuestion) {
      await submitEmptyAnswer(nextQuestion)
    }

    if (isLastQuestion) {
      await onFinishQuiz()
    }
  }

  const onNavigateBack = () => {
    setIsReversed(true)
  }

  const onSkipQuestion = async () => {
    setIsReversed(false)
    clearAnswer()
    await submitEmptyAnswer(currentQuestion)

    let _nextQuestion = nextQuestion

    if (
      currentQuestion.questionType === QUESTION_TYPES.PHONE_NUMBER &&
      _nextQuestion?.questionType === QUESTION_TYPES.PHONE_VERIFICATION
    ) {
      // If the current question is phone number input and the next one is phone verification
      // mark the phone verification as a question that should be skipped on navigation
      markQuestionToSkip(_nextQuestion.id)

      // Send empty phone verification code
      await submitEmptyAnswer(_nextQuestion)

      // Track skipping phone question
      obe.analytics.track(obe.events.quiz.skipped_phone_question, trackingData)

      // Should navigate to question after phone number verification
      _nextQuestion = questions[currentQuestionIdx + 2]
    }

    if (currentQuestion.questionType === QUESTION_TYPES.PHONE_VERIFICATION) {
      // If the current question is the phone verification one and the user decides to skip it
      // an empty answer to the phone number question must be sent to the backend
      const phoneNumberQuestion = questions.find(
        (question) => question.questionType === QUESTION_TYPES.PHONE_NUMBER
      )
      if (!phoneNumberQuestion) {
        // This should never occur in a quiz with the correct structure
        return
      }
      await submitEmptyAnswer(phoneNumberQuestion)
    }

    // check if end of quiz has been reached
    if (_nextQuestion) {
      push(`/quiz/${quiz.slug}/${_nextQuestion.slug}`)
    } else {
      await onFinishQuiz()
      push(resultPageRoutes[quiz.slug])
    }
  }

  const onChangeHandler = React.useMemo(
    () =>
      [
        QUESTION_TYPES.WITH_IMAGE,
        QUESTION_TYPES.TEXT_ONLY,
        QUESTION_TYPES.GENDER,
        QUESTION_TYPES.FITNESS_LEVEL,
      ].includes(currentQuestion.questionType)
        ? optionSelectHandler
        : textInputHandler,
    [currentQuestion, optionSelectHandler, textInputHandler]
  )

  const previousRoute = previousAvailableQuestion
    ? `/quiz/${quiz.slug}/${previousAvailableQuestion.slug}`
    : ''

  const nextRoute = isLastQuestion
    ? resultPageRoutes[quiz.slug]
    : nextAvailableQuestion
    ? `/quiz/${quiz.slug}/${nextAvailableQuestion.slug}`
    : ''

  const contextState = {
    onChangeHandler,
    onNavigateBack,
    onSkipQuestion,
    previousAnswer,
    currentAnswer,
    onNavigateForward,
    startPhoneVerification,
    currentQuestion,
    isAnswerValid,
    setIsAnswerValid,
    isBusy: isLoadingPreviousAnswer || isSubmittingAnswer || isFinishingQuiz,
    isLoadingPreviousAnswer,
    currentQuestionIdx,
    totalQuestions: questions.length,
    previousRoute,
    nextRoute,
    trackingAnswerData,
  }
  return <QuizResultsContext.Provider value={contextState}>{children}</QuizResultsContext.Provider>
}
