import { client } from 'src/api'

export const searchRelatedProfiles = async (searchString, cancelToken) =>
  await client.get(`/api/v4/profiles/related?search_str=${searchString}`, { cancelToken })

export const searchUnrelatedProfiles = async (page, perPage, searchString, cancelToken = null) => {
  return await client.get(`/api/v4/profiles/unrelated?search_str=${searchString}`, {
    params: {
      page: page,
      per_page: perPage,
    },
    cancelToken,
  })
}

export const createProfile = async (params) => await client.post('/api/v4/profiles', params)

export const updateProfile = async (userId, params) =>
  await client.put(`/api/v4/profiles/${userId}`, params)

export const updateSMSPreferences = async (params) =>
  await client.put('/api/v4/profiles/sms_preferences', params)

export const startSMSVerification = async (params) =>
  await client.post('/api/v4/profiles/start_sms_verification', params)

export const usernameAvailability = async (username, cancelToken) =>
  await client.get(`/api/v4/profiles/username_availability?username=${username}`, { cancelToken })

export const generateUsername = (id) => client.post('/api/v4/profiles/create_username', { id })
