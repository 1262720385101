import { button, breakpoint } from 'src/styles'
import { Icon } from '../Icon'
import { Link } from '../Link'

export const NavButton = ({
  action,
  buttonStyle = 'newPrimary',
  direction = 'left',
  disabled = false,
  buttonText = '',
  onClickHandler = Function.prototype,
  showChevron = true,
  tracking = {},
  to,
  containerStyle = {},
}) => {
  return (
    <Styles.NavContainer direction={direction} style={containerStyle}>
      <Styles.Button
        action={action}
        buttonStyle={buttonStyle}
        disabled={disabled}
        onClick={onClickHandler}
        to={to}
        tracking={tracking}
      >
        {buttonText && <span>{buttonText}</span>}
        {showChevron && (
          <Styles.Chevron buttonStyle={buttonStyle} disabled={disabled} direction={direction} />
        )}
      </Styles.Button>
    </Styles.NavContainer>
  )
}

const Styles = {
  NavContainer: styled.div``,
  Button: styled(Link)`
    cursor: pointer;
    ${({ buttonStyle }) =>
      buttonStyle === 'none' &&
      css`
        color: var(--neutrals700);
        background: none;
        border: none;
        outline: inherit;
        margin-top: 10px;
      `}
    ${({ buttonStyle }) =>
      buttonStyle === 'newPrimary' &&
      css`
        height: 50px;
        ${button.newPrimary}
        ${breakpoint.largeAndUp} {
          width: 240px;
        }
        ${breakpoint.smallAndDown} {
          width: 343px;
        }
      `}

    ${({ disabled, buttonStyle }) =>
      disabled &&
      buttonStyle === 'newPrimary' &&
      css`
        background: var(--neutrals500);
        color: var(--neutrals700);
        :hover {
          border-color: var(--neutrals500);
          background: var(--neutrals500);
        }
      `}

      ${({ disabled, buttonStyle }) =>
      !disabled &&
      buttonStyle === 'newPrimary' &&
      css`
        :hover {
          color: var(--neutrals100);
        }
      `}
  `,
  Chevron: styled(Icon.Chevron)`
    width: 18px;
    height: 16px;
    margin-left: 10px;
    transform: ${({ direction }) => (direction === 'left' ? 'rotate(90deg)' : 'rotate(270deg)')};

    path {
      fill: var(--neutrals700);
    }

    ${({ buttonStyle }) =>
      buttonStyle === 'newPrimary' &&
      css`
        margin-bottom: 3px;
      `}
    ${({ disabled, buttonStyle }) =>
      !disabled &&
      buttonStyle === 'newPrimary' &&
      css`
        path {
          fill: var(--neutrals100);
        }
      `}

      ${breakpoint.smallAndDown} {
      ${({ buttonStyle }) =>
        buttonStyle === 'none' &&
        css`
          margin-left: 0;
        `}
    }
  `,
}
