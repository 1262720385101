export const useQuizNavigation = (questions, questionSlug) => {
  // Questions that should be skipped on navigation based on answers given by the user
  // or if the user chose to skip certain questions (e.g. skipping phone number input
  // should skip sms code validation)
  const [questionsToSkip, setQuestionsToSkip] = React.useState([])

  const currentQuestionIdx = React.useMemo(
    () => questions.findIndex((q) => q.slug === questionSlug),
    [questionSlug, questions]
  )

  const currentQuestion = React.useMemo(
    () => questions[currentQuestionIdx] || {},
    [currentQuestionIdx, questions]
  )

  const isLastQuestion = currentQuestionIdx === questions.length - 1

  const previousAvailableQuestion = React.useMemo(
    () =>
      questions
        .slice(0, currentQuestionIdx)
        .reverse()
        .find((question) => !questionsToSkip.includes(question.id)),
    [currentQuestionIdx, questions, questionsToSkip]
  )

  const nextAvailableQuestion = React.useMemo(
    () =>
      questions
        .slice(currentQuestionIdx + 1)
        .find((question) => !questionsToSkip.includes(question.id)),
    [currentQuestionIdx, questions, questionsToSkip]
  )

  const nextQuestion = questions[currentQuestionIdx + 1]

  const markQuestionToSkip = React.useCallback(
    (questionId) => {
      if (!questionsToSkip.includes(questionId)) {
        setQuestionsToSkip([...questionsToSkip, questionId])
      }
    },
    [questionsToSkip]
  )

  const unmarkQuestionToSkip = React.useCallback(
    (questionId) => {
      if (questionsToSkip.includes(questionId)) {
        setQuestionsToSkip(questionsToSkip.filter((id) => id !== questionId))
      }
    },
    [questionsToSkip]
  )

  const isSkippingNextQuestion = !!nextQuestion && questionsToSkip.includes(nextQuestion.id)

  return {
    currentQuestionIdx,
    currentQuestion,
    previousAvailableQuestion,
    nextAvailableQuestion,
    nextQuestion,
    isLastQuestion,
    markQuestionToSkip,
    unmarkQuestionToSkip,
    isSkippingNextQuestion,
  }
}
