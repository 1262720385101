import { breakpoint } from 'src/styles'
import { Image } from 'src/components/Display/Image'
import { NavButton } from 'src/components/Quiz/NavButton'
import { useQuiz } from 'src/providers/Quiz'

export const QuizHeader = ({ backgroundWhite, className, complete, transparentBackground }) => {
  const { onNavigateBack, previousRoute, totalQuestions, currentQuestionIdx, isBusy } =
    useQuiz() || {}
  const questionNumber = currentQuestionIdx >= 0 ? currentQuestionIdx + 1 : 0

  const filledWidth =
    questionNumber && totalQuestions
      ? Math.round((questionNumber / totalQuestions) * 100)
      : complete
      ? 100
      : 0

  return (
    <Styles.OuterContainer backgroundWhite={backgroundWhite}>
      <Styles.Container className={className}>
        <Styles.Content transparentBackground={transparentBackground}>
          <NavButton
            buttonStyle='none'
            onClickHandler={onNavigateBack}
            to={previousRoute || ''}
            containerStyle={{ visibility: previousRoute ? 'visible' : 'hidden' }}
            disabled={isBusy}
          />
          <Styles.Logo src='/obe-logo-color.png' className={className} style={{ paddingTop: 0 }} />
          <Styles.ProgressPages
            visibility={questionNumber && totalQuestions ? 'visible' : 'hidden'}
          >{`${questionNumber || ''} / ${totalQuestions || ''}`}</Styles.ProgressPages>
        </Styles.Content>
      </Styles.Container>
      {filledWidth > 0 && (
        <Styles.ProgressBar>
          <Styles.ProgressUnfilled />
          <Styles.ProgressFilled width={filledWidth} />
        </Styles.ProgressBar>
      )}
    </Styles.OuterContainer>
  )
}

const Styles = {
  OuterContainer: styled.div`
    position: sticky;
    max-width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));

    ${({ backgroundWhite }) => css`
      background-color: ${backgroundWhite ? 'var(--neutrals100)' : 'transparent'};
    `}
  `,
  Container: styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 16px 80px;
    height: 64px;
    width: 100%;

    ${({ transparentBackground }) => css`
      background-color: ${transparentBackground} ? 'transparent' : 'var(--neutrals100) !important' }
    `}

    ${breakpoint.smallAndDown} {
      padding: 16px;
    }
  `,
  Content: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
  `,
  ProgressFilled: styled.div`
    height: 4px;
    position: absolute;
    z-index: 2;
    background-color: var(--persianBlue);
    width: ${({ width }) => `${width}%`};
    max-width: 100%;
    transition: width 300ms linear;
  `,
  ProgressUnfilled: styled.div`
    height: 4px;
    background-color: var(--neutrals400);
    position: absolute;
    width: 100%;
  `,
  ProgressBar: styled.div`
    position: relative;
    max-width: 100%;
  `,
  ProgressPages: styled.div`
    color: black !important;
    font-weight: 350;
    font-size: 16px;
    visibility: ${({ visibility }) => `${visibility}`};
    min-width: 60px;
    text-align: right;
  `,
  Logo: styled(Image)`
    display: block;
    margin: 0 auto;
    width: 70px;
    padding: 0 !important;

    img {
      position: relative !important;
    }
    ${breakpoint.smallAndDown} {
      width: 58px;
      height: 24px;
    }
  `,
}

QuizHeader.propTypes = {
  LeftNav: PropTypes.node,
  RightNav: PropTypes.node,
}
