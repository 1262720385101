import { QUESTION_TYPES } from 'src/providers/Quiz/utils'

export const useQuizTracking = (quiz, currentQuestion, currentAnswer) => {
  const trackingData = React.useMemo(
    () => ({
      quizSlug: quiz.slug,
    }),
    [quiz]
  )

  const trackingAnswerData = React.useMemo(
    () => ({
      ...trackingData,
      name: currentQuestion.trackingName,
      quizAnswer: [
        QUESTION_TYPES.WITH_IMAGE,
        QUESTION_TYPES.TEXT_ONLY,
        QUESTION_TYPES.FITNESS_LEVEL,
      ].includes(currentQuestion.questionType)
        ? currentAnswer.choices.map((choice) => choice.value)
        : [],
    }),
    [currentAnswer.choices, currentQuestion, trackingData]
  )

  return { trackingData, trackingAnswerData }
}
