import { useCallback } from 'react'
import { useRouter } from 'src/hooks/useRouter'
import { QUESTION_TYPES, resultPageRoutes } from 'src/providers/Quiz/utils'
import { useQuizNavigation } from './useQuizNavigation'
import { usePrePaywallQuizData } from './usePrePaywallQuizData'
import { useQuizTracking } from './useQuizTracking'
import { QuizResultsContext } from './QuizProvider'
import { getQuestionUrl, getPrePaywallAnswers } from './utils'
import { Quiz } from 'src/types/Quiz'

interface PrePaywallQuizProviderProps {
  quiz: Quiz
  questionSlug: string
  setIsReversed: (reversed: boolean) => void
  children: React.ReactElement
}

export const PrePaywallQuizProvider = ({
  quiz,
  questionSlug,
  setIsReversed,
  children,
}: PrePaywallQuizProviderProps) => {
  const { questions } = quiz
  const answers = getPrePaywallAnswers()
  const { push } = useRouter()

  const {
    currentQuestion,
    currentQuestionIdx,
    previousAvailableQuestion,
    nextAvailableQuestion,
    nextQuestion,
    markQuestionToSkip,
    unmarkQuestionToSkip,
    isLastQuestion,
    isSkippingNextQuestion,
  } = useQuizNavigation(questions, questionSlug)

  const {
    previousAnswer,
    currentAnswer,
    isAnswerValid,
    setIsAnswerValid,
    submitAnswer,
    submitEmptyAnswer,
    clearAnswer,
    optionSelectHandler,
    textInputHandler,
  } = usePrePaywallQuizData(
    currentQuestion,
    nextQuestion,
    markQuestionToSkip,
    unmarkQuestionToSkip,
    answers
  )

  const { trackingData, trackingAnswerData } = useQuizTracking(quiz, currentQuestion, currentAnswer)

  // Send quiz finish tracking data
  const trackQuizFinish = useCallback(
    () => obe.analytics.track(obe.events.quiz.completed, trackingData),
    [trackingData]
  )

  const routeToQuizPreview = useCallback(() => push('/obe-preview'), [push])

  // If invalid question route redirect to valid one
  React.useEffect(() => {
    if (questions?.length > 0 && currentQuestionIdx < 0) {
      push(getQuestionUrl(quiz.slug, questions[0].slug))
    }
  }, [currentQuestion, currentQuestionIdx, push, questions, quiz.slug])

  const onNavigateForward = async () => {
    setIsReversed(false)
    submitAnswer(currentQuestion)

    // If the next question has been marked as a question to be skipped, submit an empty answer
    // for it as well
    if (isSkippingNextQuestion) {
      submitEmptyAnswer(nextQuestion)
    }

    if (isLastQuestion) {
      routeToQuizPreview()
      trackQuizFinish()
    }
  }

  const onNavigateBack = () => {
    setIsReversed(true)
  }

  const onSkipQuestion = async () => {
    setIsReversed(false)
    clearAnswer()
    submitEmptyAnswer(currentQuestion)

    // check if end of quiz has been reached
    if (nextQuestion) {
      push(getQuestionUrl(quiz.slug, nextQuestion.slug))
    } else {
      trackQuizFinish()
      routeToQuizPreview()
    }
  }

  const onChangeHandler = React.useMemo(
    () =>
      [
        QUESTION_TYPES.WITH_IMAGE,
        QUESTION_TYPES.TEXT_ONLY,
        QUESTION_TYPES.GENDER,
        QUESTION_TYPES.FITNESS_LEVEL,
      ].includes(currentQuestion.questionType)
        ? optionSelectHandler
        : textInputHandler,
    [currentQuestion, optionSelectHandler, textInputHandler]
  )

  const previousRoute = previousAvailableQuestion
    ? getQuestionUrl(quiz.slug, previousAvailableQuestion.slug)
    : ''

  const nextRoute = isLastQuestion
    ? resultPageRoutes[quiz.slug]
    : nextAvailableQuestion
    ? getQuestionUrl(quiz.slug, nextAvailableQuestion.slug)
    : ''

  const contextState = {
    onChangeHandler,
    onNavigateBack,
    onSkipQuestion,
    previousAnswer,
    currentAnswer,
    onNavigateForward,
    currentQuestion,
    isAnswerValid,
    setIsAnswerValid,
    currentQuestionIdx,
    totalQuestions: questions.length,
    previousRoute,
    nextRoute,
    trackingAnswerData,
  }

  return <QuizResultsContext.Provider value={contextState}>{children}</QuizResultsContext.Provider>
}
