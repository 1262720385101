import { DEFAULT_EMPTY_ANSWER, submitPrePaywallAnswer } from 'src/providers/Quiz/utils'
import {
  PrePaywallAnswer,
  PrePaywallStoredAnswersType,
  QuizChoice,
  QuizQuestion,
} from 'src/types/Quiz'

export const usePrePaywallQuizData = (
  currentQuestion: QuizQuestion,
  nextQuestion: QuizQuestion,
  markQuestionToSkip: (id: string) => void,
  unmarkQuestionToSkip: (id: string) => void,
  savedAnswers: PrePaywallStoredAnswersType
) => {
  const [isAnswerValid, setIsAnswerValid] = React.useState(false)
  const [answers, setAnswers] = React.useState<PrePaywallStoredAnswersType>(savedAnswers)

  const currentAnswer = React.useMemo(
    () => answers[currentQuestion.id] ?? DEFAULT_EMPTY_ANSWER,
    [answers, currentQuestion]
  )

  const previousAnswer = React.useMemo(() => {
    const dependencyId = currentQuestion.dependentQuestionId
    if (dependencyId && answers[dependencyId] !== undefined) {
      return answers[dependencyId]
    }
    return DEFAULT_EMPTY_ANSWER
  }, [answers, currentQuestion])

  const submitAnswer = React.useCallback(
    (question: QuizQuestion) => {
      submitPrePaywallAnswer(question, answers[question.id])
    },
    [answers]
  )

  const submitEmptyAnswer = (question: QuizQuestion) =>
    submitPrePaywallAnswer(question, {
      ...DEFAULT_EMPTY_ANSWER,
      question: { id: question.id, slug: question.slug },
    })

  const updateAnswer = React.useCallback(
    (answer: PrePaywallAnswer) => {
      setAnswers((prev) => ({
        ...prev,
        [currentQuestion.id]: answer,
      }))
    },
    [currentQuestion]
  )

  const clearAnswer = React.useCallback(() => {
    setAnswers((prev) => ({
      ...prev,
      [currentQuestion.id]: {
        ...DEFAULT_EMPTY_ANSWER,
        question: {
          id: currentQuestion.id,
          slug: currentQuestion.slug,
        },
      },
    }))
  }, [currentQuestion])

  const optionSelectHandler = React.useCallback(
    (option: QuizChoice) => {
      const currentChoices = currentAnswer.choices
      const hasMutuallyExclusiveOptionSelected = currentChoices.some(
        (selectedOption) => selectedOption.mutuallyExclusive
      )
      const optionIsCurrentlySelected = currentChoices.some(
        (selectedOption) => selectedOption.id === option.id
      )

      let newAnswer: QuizChoice[] = []

      // Update answer value
      if (optionIsCurrentlySelected) {
        newAnswer = currentChoices.filter((selectedOption) => selectedOption.id !== option.id)
      } else if (
        !currentQuestion.multiSelect ||
        hasMutuallyExclusiveOptionSelected ||
        option.mutuallyExclusive
      ) {
        newAnswer = [option]
      } else {
        newAnswer = [...currentChoices, option]
      }

      updateAnswer({
        value: DEFAULT_EMPTY_ANSWER.value,
        choices: newAnswer,
        question: {
          id: currentQuestion.id,
          slug: currentQuestion.slug,
        },
      })

      // Verify if next question should be skipped based on options selected
      if (nextQuestion) {
        const shouldSkipNextQuestion =
          newAnswer.length > 0 &&
          newAnswer.filter((option: QuizChoice) => option.skipNextQuestion).length ===
            newAnswer.length
        if (shouldSkipNextQuestion) {
          markQuestionToSkip(nextQuestion.id)
        } else {
          unmarkQuestionToSkip(nextQuestion.id)
        }
      }
    },
    [
      currentAnswer.choices,
      currentQuestion,
      markQuestionToSkip,
      nextQuestion,
      unmarkQuestionToSkip,
      updateAnswer,
    ]
  )

  const textInputHandler = React.useCallback(
    (value: string) => {
      if (nextQuestion) {
        unmarkQuestionToSkip(nextQuestion.id)
      }

      updateAnswer({
        value: value,
        choices: DEFAULT_EMPTY_ANSWER.choices,
        question: {
          id: currentQuestion.id,
          slug: currentQuestion.slug,
        },
      })
    },
    [nextQuestion, currentQuestion, unmarkQuestionToSkip, updateAnswer]
  )

  return {
    previousAnswer,
    currentAnswer,
    isAnswerValid,
    setIsAnswerValid,
    submitAnswer,
    submitEmptyAnswer,
    clearAnswer,
    optionSelectHandler,
    textInputHandler,
  }
}
