import moment from 'moment'
import * as Sentry from '@sentry/nextjs'
import { e164Formatted } from './utils'
import { useAsyncCallback } from 'react-async-hook'
import { QUESTION_TYPES } from 'src/providers/Quiz/utils'
import { getQuestionAnswerById, submitQuizAnswer } from 'src/models/quizzes'

const DEFAULT_EMPTY_ANSWER = { value: '', choices: [] }

export const useQuizData = (
  currentQuestion,
  nextQuestion,
  quiz,
  markQuestionToSkip,
  unmarkQuestionToSkip
) => {
  const [isAnswerValid, setIsAnswerValid] = React.useState(false)
  const [answers, setAnswers] = React.useState({})

  const { execute: fetchPreviousAnswer, loading: isLoadingPreviousAnswer } = useAsyncCallback(
    async (questionId) => {
      let answer = DEFAULT_EMPTY_ANSWER
      try {
        const { responses } = await getQuestionAnswerById(quiz.id, questionId)
        // Determine if it's a singular, textual response or a multiple choice type of answer
        if (responses?.[0]?.textResponse) {
          answer = {
            value: responses[0].textResponse,
            choices: [],
          }
        } else if (responses?.length > 0) {
          answer = {
            value: '',
            choices: responses.map((response) => response.quizChoice),
          }
        }
      } catch (err) {
        Sentry.captureException(err, { level: 'debug' })
      }
      setAnswers({ ...answers, [questionId]: { ...answer } })
    }
  )

  const { execute: submitAnswer, loading: isSubmittingAnswer } = useAsyncCallback(
    async (question, blank = false) => {
      const answer = !blank ? answers[question.id] : { ...DEFAULT_EMPTY_ANSWER }
      let answerData = {
        value: answer.value,
        choices: answer.choices.map((choice) => choice.id),
      }
      if (!blank) {
        // Gender type questions need to send choice string as value
        if (question.questionType === QUESTION_TYPES.GENDER) {
          answerData = {
            value: answer.choices[0]?.choice ?? '',
            choices: [],
          }
        }
        // Properly format phone number before sending it
        if (question.questionType === QUESTION_TYPES.PHONE_NUMBER) {
          answerData = {
            value: e164Formatted(answer.value),
            choices: [],
          }
        }
        // Properly format birthdate
        if (question.questionType === QUESTION_TYPES.BIRTHDAY) {
          answerData = {
            value: moment(answer.value, 'MM/DD/YYYY', true).format('YYYY-MM-DD'),
            choices: [],
          }
        }
      }
      try {
        await submitQuizAnswer(quiz.id, {
          question_id: question.id,
          ...answerData,
        })
      } catch (err) {
        Sentry.captureException(err, { level: 'debug' })
      }
    }
  )

  const currentAnswer = React.useMemo(
    () => answers[currentQuestion.id] ?? DEFAULT_EMPTY_ANSWER,
    [answers, currentQuestion]
  )

  const previousAnswer = React.useMemo(() => {
    const dependencyId = currentQuestion.dependentQuestionId
    if (dependencyId) {
      if (answers[dependencyId] !== undefined) {
        return answers[dependencyId]
      } else {
        // If a previous answer is not readily available fetch it from the backend
        fetchPreviousAnswer(dependencyId)
      }
    }
    return DEFAULT_EMPTY_ANSWER
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, currentQuestion])

  // Wrapper function for readability
  const submitEmptyAnswer = (question) => submitAnswer(question, true)

  const updateAnswer = React.useCallback(
    (answer) => {
      setAnswers({
        ...answers,
        [currentQuestion.id]: answer,
      })
    },
    [answers, currentQuestion]
  )

  const clearAnswer = React.useCallback(() => {
    setAnswers({
      ...answers,
      [currentQuestion.id]: { ...DEFAULT_EMPTY_ANSWER },
    })
  }, [answers, currentQuestion])

  const optionSelectHandler = React.useCallback(
    (option) => {
      const currentChoices = currentAnswer.choices
      const hasMutuallyExclusiveOptionSelected = currentChoices.some(
        (selectedOption) => selectedOption.mutuallyExclusive
      )
      const optionIsCurrentlySelected = currentChoices.some(
        (selectedOption) => selectedOption.id === option.id
      )

      let newAnswer = []

      // Update answer value
      if (optionIsCurrentlySelected) {
        newAnswer = currentChoices.filter((selectedOption) => selectedOption.id !== option.id)
      } else if (
        !currentQuestion.multiSelect ||
        hasMutuallyExclusiveOptionSelected ||
        option.mutuallyExclusive
      ) {
        newAnswer = [option]
      } else {
        newAnswer = [...currentChoices, option]
      }

      updateAnswer({ value: DEFAULT_EMPTY_ANSWER.value, choices: newAnswer })

      // Verify if next question should be skipped based on options selected
      if (nextQuestion) {
        const shouldSkipNextQuestion =
          newAnswer.length > 0 &&
          newAnswer.filter((option) => option.skipNextQuestion).length === newAnswer.length
        if (shouldSkipNextQuestion) {
          markQuestionToSkip(nextQuestion.id)
        } else {
          unmarkQuestionToSkip(nextQuestion.id)
        }
      }
    },
    [
      currentAnswer.choices,
      currentQuestion.multiSelect,
      markQuestionToSkip,
      nextQuestion,
      unmarkQuestionToSkip,
      updateAnswer,
    ]
  )

  const textInputHandler = React.useCallback(
    (value) => {
      if (nextQuestion) {
        unmarkQuestionToSkip(nextQuestion.id)
      }

      updateAnswer({ value: value, choices: DEFAULT_EMPTY_ANSWER.choices })
    },
    [nextQuestion, unmarkQuestionToSkip, updateAnswer]
  )

  return {
    previousAnswer,
    currentAnswer,
    isAnswerValid,
    setIsAnswerValid,
    isLoadingPreviousAnswer,
    submitAnswer,
    submitEmptyAnswer,
    clearAnswer,
    isSubmittingAnswer,
    optionSelectHandler,
    textInputHandler,
  }
}
