import { client } from 'src/api'

export const getOnboardingQuizBySlug = async (slug = '') => {
  const { data } = await client.get('/api/v4/onboarding_quizzes/by_slug', { params: { slug } })
  return data
}

export const getQuestionAnswerById = async (quizId, questionId) => {
  const { data } = await client.get(`/api/v4/onboarding_quizzes/${quizId}/question_response`, {
    params: { question_id: questionId },
  })
  return data
}

export const submitQuizAnswer = async (quizId, params) => {
  const { data } = await client.put(
    `/api/v4/onboarding_quizzes/${quizId}/update_quiz_responses`,
    params
  )
  return data
}

export const getPrePaywallQuiz = async () => {
  const { data } = await client.get('/api/v4/onboarding_quizzes/preview_quiz')
  return data
}

export const submitPrePaywallQuizAnswers = async (answers) => {
  const { data } = await client.post('/api/v4/onboarding_quizzes/bulk_save_quiz', {
    quiz_results: answers,
  })
  return data
}
